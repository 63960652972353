*{
  padding:0;
  margin:0;
  box-sizing: border-box;
  background-color: white;
  color: black;
  font-family: 'Girassol', serif;
font-family: 'Noto Sans', sans-serif;
}
.some-container{
  display: grid;
  grid-template-areas:
        "A A A A A A A A A A A A"
        "B B B B B B B B B B B B"
        "C C C C C C C C C C C C"
        "D D D D D D D D D D D D"
        "E E E E E E E E E E E E"
        "F F F F F F F F F F F F"
        "G G G G G G G G G G G G"
        "H H H H H H H H H H H H"
        "I I I I I I I I I I I I"
        "J J J J J J J J J J J J"
        "K K K K K K K K K K K K"
        "L L L L L L L L L L L L";
}
.nav{
  display: flex;
  /*gap: 350px;*/
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
}
header{
  color: black;
  /*background-color: black;*/
  padding:20px;
  grid-area: A;
}
header a{
  color: black;
  text-decoration: none;
}
.brand{
  grid-column-start: 1;
    grid-column-end: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  gap:15px;
  font-size: 1.8rem;
}
.show-on-hover{
  opacity: 0;
  transition: all 0.5s;
  font-size: 1rem;
}
.brand:hover .show-on-hover{
  opacity: 1;
  transition: all 0.5s;
}
.middle-text h3{
  color: #0068A5;
  font-weight: bolder;
}
.beta{
  font-weight: 100;
}
.btns{
  grid-column-start: 9;
  grid-column-end: 11;
  display: flex;
  align-items: center;
  gap: 15px;
  font-size:1.5rem;
}
.exit-btn{
  font-size:1.5rem;
}
.exit-btn a{
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
.reset-border{
  width:0%;
  height: 1px;
  margin-top:5px;
  background-color: black;
  transition: all 0.5s;
}
.exit-border{
  width:0%;
  height: 1px;
  margin-top:5px;
  background-color: black;
  transition: all 0.5s;
}
.reset-btn a{
  font-size: 16px;
  font-weight: bold;
}
.reset-btn img{
  width: 17px;
  height: 17px;
}
.exit-btn:hover .exit-border,
.reset-btn:hover .reset-border{
  width: 100%;
  transition: all 0.5s;
}
main{
  display: flex;
  justify-content: space-evenly;
  /*gap: 60px;*/
  /*gap: 39px;*/
  /*margin: 25px;*/
  height: 80vh;
  /*margin-top:50px;*/
  
  /*margin: 50px auto 0 auto;*/
  margin: 0 auto 0 auto;
}
.show-chat-around{
  justify-content: space-around;
}
/**/
.summary-hide{
  visibility: hidden;
}
.summary-mobile{
  display: none;
}
.summary-heading{
  font-size: 18px;
  font-family: 'IBM Plex Sans';
  color: #3788b8;
  margin-bottom: 35px;
  height: 5px;
  line-height: 1.8;
  font-weight: bold;
}
.summary-text{
  font-size: 14px;
  color: black;
  line-height: 1.8;
  font-family: 'IBM Plex Sans';
  height: 80px;
}
.blue-circle{
  width: 10px;
  height: 10px;
  background-color: #0068A5;
  border-radius: 50%;
  margin-top: 5px;
  padding: 5px;
  background-image: url('./assets/gif/loader.gif');
}
.option{
  padding:10px 15px;
  background-color: #BAEAFF;
  width: 250px;
  margin:0 auto;
  font-weight: 100;
  line-height: 1.8;
  font-size: 0.9rem;
  cursor: pointer;
  color: #084C69;
  font-weight: bold;
}
.option-hidden{
  display: none;
  font-family: 'IBM Plex Sans';
  font-weight: 14px;
  width: 94%;
  background-color: #EBEBEB;
  padding: 15px;
  color: black;
  border-radius: 15px;
  margin-left: 15px;
  align-items: flex-start;
  gap: 5px;
  position: relative;
}
.option-hidden:nth-child(odd)::before{
  content: '^';
  background-color: #EBEBEB;
  color: #EBEBEB;
  position: absolute;
  top:-10px;
  left:120px;
  padding: 5px;
  border-radius: 50%;
}
.option-hidden:nth-child(even)::before{
  content: '^';
  background-color: #EBEBEB;
  color: #EBEBEB;
  position: absolute;
  top:-10px;
  right:120px;
  padding: 5px;
  border-radius: 50%;
}
.option:hover .option-hidden{
  display: flex;
}
.chat-header{
  width: 565px;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
  color: #191818;
  font-weight: bold;
  line-height: 1.8;
}
.options{
  display: flex;
  justify-content: flex-start;
  width:565px;
  flex-wrap: wrap;
  row-gap:15px;
  margin:20px auto;
}
textarea{
  /*padding: 30px 0 10px 15px;*/
  /*padding: 20px 0 0px 15px;*/
  padding: 10px 0 0px 15px;
  height: 50px;
}
textarea::placeholder{
  /*line-height: 42px;*/
}
.chat-input{
  /*padding: 20px;*/
  /*padding: 15px;*/
  width: 100%;
  border:1px solid gray;
  border-right: none;
  color: #191818;
  font-size: 16px;
  font-family: 'Lato', sans-serif;
}
.chat-input-disabled{
  cursor: not-allowed;
}
.chat-input:focus{
  outline: none;
}
.send-btn{
  border:1px solid gray;
  border-left: none;
  cursor: pointer;
  padding-right: 15px;
}
.top-chat-area{
  max-height: 80vh;
}
.chat-box{
  display: flex;
  padding-top: 20px;
  margin-top: 20px;
  width: 565px;
}
.chat-section{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.green-square{
  width: 25px;
  height: 25px;
  flex-shrink: 0;
  /*background-image: url('logo-aida.svg');*/
  /*background-size: contain;*/
  background-repeat: no-repeat;
  background-image: url('Mask_Group_6.svg');
  background-size: cover;
}
.green-and-text{
  display: flex;
  gap: 15px;
}
/* chat feature */
.user,
.app{
  display: flex;
  gap:15px;
  margin-bottom: 25px;
  width: 550px;
}
.yellow-circle{
  width: 15px;
  height: 15px;
  background-color: #0068A5;
  border-radius: 50%;
  padding: 7px;
  margin-right: 12px;
}
.conversation-window{
  width: 100%;
  height: auto;
}
.gif-section{
  /*overflow: scroll;*/
  height: 80vh;
}
.gif-section img{
  height: 80%;
  margin:0 auto;
  max-width: 100%;
}
/* footer */
footer p{
  margin-top: 50px;
  line-height: 1.5rem;
  color: #000000;
  font-family: 'Lato', sans-serif;
  font-size: 11px;
  font-weight: bold;
  /*position: fixed;
  bottom: 0;*/
}
/* scrollbar color */
::-webkit-scrollbar {
  width: 15px;
  height: 15px;
  }
  ::-webkit-scrollbar-track-piece  {
  background-color: white;
  }
  ::-webkit-scrollbar-thumb:vertical {
  height: 30px;
  background-color: white;
  }
  /* sources */
  .source-content{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin:15px 0;
  }
  .source-header-body{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    letter-spacing: 0.2rem;
  }
  .source-img{
    width: 103px;
    height: 88px;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000012;
  }
  .msg-user{
    font-weight: bold;
    font-size: 14px;
    font-family: 'Lato';
    color: #084C69;
  }
  .msg-user div{
    color: #3788b8;
  }
  .msg-app div{
    color: #191818;
    font-family: 'IBM Plex Sans';
    line-height: 2;
    font-size: 14px;
  }
  .horizontal-divider{
    width: 100%;
    height: 1px;
    background-color: #959595;
    margin: 25px 0;
  }
  
  .gif-section-1,
  .gif-section-2{
    /*width: 666px;*/
  }
  .gif-section-2 h3{
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 18px;
  }
  .gif-section-2{
    overflow: scroll;
    height: 80vh;
  }
  .hide-gif-panel{
    display: none;
  }
  .gif-section-1 img{
    width: 386px;
    height: 386px;
    overflow: hidden;
    grid-row-start: 3;
    grid-row-end: 3;
    grid-column-start: 2;
  }
  .like-icons{
    display: flex;
    gap: 15px;
    justify-content: flex-end;
  }
  .like-icons img{
    cursor: pointer;
  }
  
  @keyframes dots-1 {
    0%{
      transform: translateY(-20px);
    }

    50%{
      transform: translateY(-10px);
    }

    100%{
      transform: translateY(-20px);
    }
  }
  @keyframes dots-2 {
    0%{
      transform: translateY(-10px);
    }

    50%{
      transform: translateY(-20px);
    }

    100%{
      transform: translateY(-10px);
    }
  }
  @keyframes dots-3 {
    0%{
      transform: translateY(-10px);
    }

    50%{
      transform: translateY(-20px);
    }

    100%{
      transform: translateY(-10px);
    }
  }
#first-dot{
  color: #0068A5;
  font-size: 1.5rem;
  animation-name: dots-1;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  font-size: 5rem;
  height: 1px;
  background: transparent;
}
#second-dot{
  color: #0068A5;
  font-size: 1.5rem;
  animation-name: dots-2;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
  font-size: 5rem;
  height: 1px;
  background: transparent;
}
#third-dot{
  color: #0068A5;
  font-size: 1.5rem;
  animation-name: dots-3;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  font-size: 5rem;
  height: 1px;
  background: transparent;
}
#animated-dots{
  display: flex;
  transform: translateY(-50px);
  background-color: transparent;
}
.source-header{
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 16px;
  letter-spacing: 0;
}
.source-body{
  font-family: 'IBM Plex Sans', sans-serif;
  font-size: 14px;
  letter-spacing: 0;
  color: #959595;
}
  .source-link{
    text-decoration: none;
  }
  .error{
    color: red;
    background: white;
    font-weight: bolder;
  }
  .error-in-chat{
    color: red;
  }
  /* end of sources */

  /* copy-chat-modal */
  .inner-copy-chat-modal{
    width:500px;
    /*height: 600px;*/
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 15px;
    padding: 15px;
    font-family: 'IBM Plex Sans Condensed'
  }
  .verify-url-copied,
  .verify-url-copied p{
    background-color: #DDFAEF;
  }
  .verify-url-copied{
    width: 310px;
    height: 50px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    top:116px;
    border-left: 5px solid #4AA181;
  }
  .copy-msg{
    background-color: #DDFAEF;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
  .copy-msg p{
    padding: 0;
    margin: 0 0 0 15px;
  }
  .copy-msg img{
    background-color: #DDFAEF;
    margin: 0 15px 0 0;
  }
  .chat-history{
    height: 250px;
    box-shadow: 0 0 3px gray;
    border-radius: 15px;
    padding: 15px 50px;
    margin-bottom: 15px;
  }
  .horizontal-line{
    width: 100%;
    background-color: rgba(128, 128, 128, 0.281);
    height: 1px;
    margin: 15px 0;
  }
  .buttons-section{
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding:0 15px;
  }
  .situation-section{
    margin-right: auto;
    padding:0 15px;
  }
  .situation-section h5{
    font-weight: bold;
  }
  .heading-section{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .close-btn{
    border: none;
  }
  .outer-copy-chat-modal{
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(128, 128, 128, 0.5);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .share-page{
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    /*gap:25px;*/
    width: 680px;
    margin: 50px auto;
  }
  .share-heading{
    font-weight: bold;
  }

  .user-2,
  .app-2{
    /*max-width: 400px;*/
    /*padding: 10px 5px;
    border-radius: 15px;*/
    width: 100%;
  }
  .app-2{
    /*margin-left: auto;*/
  }

  /* end of copy-chat-modal */
  .testPDFStyle .msg-user,
  .testPDFStyle .msg-app div{
    font-size: 0.5rem;
    font-weight: normal;
  }
  .testPDFStyle .user-2,
  .testPDFStyle .app-2{
    margin-bottom: 15px;
  }
  /*specificity issue*/
  .testPDFStyle.conversation-window-share{
    width: 60%;
  }
  .testPDFStyle .bot-icon{
    width: 15px;
    height: 15px;
    margin-right: 5px;
  }
  .testPDFStyle .app-2{
    width: 90%;
  }
  .conversation-window-share{
    width:100%;
    margin:10px auto 10px 0;
    padding:15px 10px;
    font-size:1rem
  }
  /* responsive */
  @media screen and (max-width:1645px){

  }
  @media screen and (max-width:1399px){
    .nav{
      gap: 0;
      display: flex;
      justify-content: space-between;
    }
  }
  @media screen and (max-width:1199px){
    .nav{
      gap: 0;
      justify-content: space-between;
    }
  }
  @media screen and (max-width:1175px){
    .chat-box{
      width: 100%;
    }
    .gif-section-1 img{
      grid-column-start: 1;
    grid-column-end: 3;
    }
    .nav{
      gap:0;
    }
  }
  @media screen and (max-width:1035px){
    main.container-lg{
      justify-content: space-between;
      flex-direction: column-reverse;
      padding-right:0;
      /*margin-top: 80px;*/
      /*margin-top: 175px;*/
      /*width: 85%;*/
    }
    header.container-lg{
      /*width: 80%;*/
    }
    div.user,
    div.app{
      width: 100%;
    }
    .gif-section,
    .chat-section{
      width: 100%;
    }
    .chat-section{
      height: 80vh;
      overflow: scroll;
    }
    .gif-section{
      height: 220px;
      /*border-bottom: 2px solid gray;*/
      overflow: scroll;
    }
    .summary-heading,
    .summary-text{
      visibility: visible;
    }
    .summary-mobile{
      display: block;
    }
    .loader-gif-img{
      display: none;
    }
    li.brand{
      /*margin: 0 auto;*/
    }
  }
  @media screen and (max-width:991px){
    .nav{
      gap: 0;
    }
  }
  @media screen and (max-width:912px){
    .top-chat-area{
      height: auto;
    }
  }
  @media screen and (max-width:750px){
    .middle-text{
      right: 0;
    }
    .options{
      flex-direction: column;
      width: 100%;
      flex-wrap: nowrap;
    }
    .option{
      width: 90%;
      /*font-size: 1.4rem;*/
    }
    .conversation-window{
      width: 100%;
    }
    .share-page{
      width: 90%;
    }
  }
  @media screen and (max-width:670px){
    .nav{
      display: flex;
      align-items: center;
    }
  }
  @media screen and (max-width:635px){
    *{
      font-size: 0.9rem;
    }
    .chat-header{
      font-size: 1rem;
    }
    .inner-copy-chat-modal{
      width: 80%;
    }
  }
  @media screen and (max-width:545px){
    .middle-text{
      display: none;
    }
    ul.nav{
      flex-direction: column;
    }
    .chat-box{
      /*width: 95%;*/
    }
  }
  /*@media screen and (max-width:395px){
    .middle-text{
      display: none;
    }
    ul.nav{
      flex-direction: column;
    }
  }*/
  @media screen and (max-width:355px){
    .top-chat-area{
      overflow-y: scroll;
    }
    .chat-section{
      overflow: auto;
    }
    .chat-box{
      width: 95%;
    }
  }